import React, { useState, createContext } from 'react';

export const LoaderContext = createContext({loading: true})

const Provider = props => {
  const [loading, setLoading] = useState(true)

  return (
    <LoaderContext.Provider
      value={{
        loading,
        setLoading: (value) => setLoading(value)
      }}
    >{props.children}</LoaderContext.Provider>
  )
}

const LoaderProvider = ({ element }) => (
  <Provider>{element}</Provider>
)

export default LoaderProvider